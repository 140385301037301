<template>
    <div class="dating-candidate-select" @scroll="onScroll">
        <div class="header-bar">
            <transition name="slide-down">
                <div v-show="showHiddenHeader" class="title" v-html="'커플 후기 작성하기'"></div>
            </transition>
            <div @click="$stackRouter.pop()" class="left flex-row items-center flex-wrap">
                <i class="material-icons flex-wrap">chevron_left</i>
            </div>
        </div>
        <div class="header" v-html="'커플 후기 작성하기'" />
        <div class="desc-title" v-html="descTitle" />
        <ul class="desc">
            <li>14일 이후 ~ 50일 이내의 매칭건만 선택 가능해요.</li>
            <li>상대방이 탈퇴한 경우 선택이 불가해요.</li>
        </ul>
        <div class="candidates">
            <div
                class="candidates-cont"
                v-for="candidate in candidates"
                :key="candidate.id"
                @click="clickCandidate(candidate)"
            >
                <i class="material-icons" v-if="selected === candidate">radio_button_checked</i>
                <i class="material-icons unselected" v-else>radio_button_unchecked</i>
                <img class="profile-image" :src="candidate.dating_user_photo" />
                <div class="profile-info">
                    <div class="name-age" v-html="`${candidate.dating_user_name}, ${candidate.dating_user_age}`"></div>
                    <div class="agent-date">
                        <div class="box" v-html="`주선자 ${candidate.agent_name}`" />
                        <div class="box m-l-6">{{ candidate.created_at | formatDate('MM월 DD일') }} 매칭</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bottom">
            <div class="button" :class="{ active: candidateSelected }" @click="next">다음</div>
        </div>
    </div>
</template>

<script>
import datingService from '@/services/dating'

export default {
    name: 'DatingCandidateSelectPage',
    data: () => ({
        showHiddenHeader: false,
        candidates: [],
        selected: null,
    }),
    computed: {
        title() {
            if (this.agent) {
                return `주선자 ${this.agent.name}-실제 커플 후기`
            } else {
                return '실제 커플 후기'
            }
        },
        candidateSelected() {
            return this.selected !== null
        },
        descTitle() {
            if (this.candidates.length > 0) return '축하드려요! 교제 중인 분을 선택해 주세요! 💕'
            else return '후기를 작성할 수 있는 소개팅 내역이 없어요 😢'
        },
    },
    methods: {
        async init() {
            this.candidates = await datingService.reviewCandidate()
        },
        clickCandidate(candidate) {
            this.selected = candidate
        },
        next() {
            if (this.selected !== null) {
                this.$stackRouter.push({ name: 'DatingReviewPostPage', props: { target: this.selected } })
            }
        },
        onScroll(event) {
            this.showHiddenHeader = event.target.scrollTop > 120
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.dating-candidate-select {
    padding-top: 56px;
    padding-bottom: 80px;
    overflow-y: auto;

    .header-bar {
        position: fixed;
        justify-content: space-between;
        left: 0;
        right: 0;
        z-index: 2;
        border-bottom: none;

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            height: 52px;
            top: 0;
            left: 0;
            right: 0;
            background: white;
            border-bottom: 1px solid $grey-03;
            z-index: 1;
            color: black;
            @include f-medium;
        }

        .left {
            z-index: 1;
            position: fixed;
            top: 16px;
            left: 16px;

            .material-icons {
                color: $grey-08;
            }
        }
    }

    .header {
        padding: 0 20px;
        font-size: 24px;
        letter-spacing: -0.4px;
        color: black;
        @include f-medium;
    }

    .desc-title {
        padding: 0 20px;
        font-size: 15px;
        color: black;
        margin-top: 20px;
    }

    .desc {
        padding: 0 20px;
        font-size: 13px;
        color: $grey-07;
        margin-top: 10px;

        li {
            list-style-type: disc;
            margin-left: 20px;
        }
    }

    .candidates {
        margin-top: 24px;

        .candidates-cont {
            padding: 12px 18px;
            border-radius: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;

            i {
                color: $purple-primary;
                height: fit-content;

                &.unselected {
                    color: $grey-05;
                }
            }

            .profile-image {
                width: 60px;
                height: 60px;
                margin-left: 20px;
                border-radius: 24px;
                border: solid 1px $grey-02;
            }

            .profile-info {
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .name-age {
                    color: black;
                    @include f-medium;
                    font-size: 16px;
                    font-weight: 500;
                }

                .agent-date {
                    margin-top: 9px;
                    display: flex;
                    flex-direction: row;
                    font-size: 14px;
                    color: $grey-09;

                    .box {
                        background: $grey-01;
                        border-radius: 6px;
                        padding: 2px 8px;
                    }
                }
            }
        }
    }

    .bottom {
        height: 80px;
        width: 100%;
        border-top: 1px solid $grey-02;
        padding: 16px;
        position: absolute;
        bottom: 0;
        background: white;

        .button {
            height: 48px;
            width: 100%;
            line-height: 48px;
            color: $grey-05;
            font-size: 15px;
            border-radius: 8px;
            background: $grey-02;
            text-align: center;

            &.active {
                color: white;
                background: $purple-primary;
            }
        }
    }
}
</style>
