import { render, staticRenderFns } from "./DatingCandidateSelectPage.vue?vue&type=template&id=6d18b89e&scoped=true"
import script from "./DatingCandidateSelectPage.vue?vue&type=script&lang=js"
export * from "./DatingCandidateSelectPage.vue?vue&type=script&lang=js"
import style0 from "./DatingCandidateSelectPage.vue?vue&type=style&index=0&id=6d18b89e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d18b89e",
  null
  
)

export default component.exports